import { useEffect, useReducer } from 'react';
import { loadDistributionParams } from './rollapp-service';
import { RollappState, rollAppReducer } from './rollapp-state';
import { useClient } from '../client/client-context';
import { useCancelablePromise } from '../../shared/hooks/use-cancelable-promise';
import { Network } from '../network/network-types';
import { DistributionParams } from './rollapp-types';

export const useRollapp = (network: Network): RollappState => {
    const { clientStateMap, handleClientError } = useClient();
    const [ rollAppData, rollAppDataDispatch ] = useReducer(rollAppReducer, { loading: true });
    const cancelAndSetDistributionParamsPromise = useCancelablePromise<DistributionParams>();

    const clientState = clientStateMap[network.chainId];

    useEffect(() => {
        rollAppDataDispatch({ type: 'clear' });
        cancelAndSetDistributionParamsPromise();
    }, [ cancelAndSetDistributionParamsPromise ]);

    useEffect(() => {
        if (network.type !== 'RollApp' || (clientState && !clientState.client && !clientState.connecting)) {
            rollAppDataDispatch({ type: 'set-loading', payload: false });
            return;
        }
        if (!clientState?.client || clientState?.connecting) {
            return;
        }
        rollAppDataDispatch({ type: 'set-loading' });
        cancelAndSetDistributionParamsPromise((signal) => loadDistributionParams(clientState.client!, signal))
            .then((params) => rollAppDataDispatch({ type: 'set-distribution-params', payload: params }))
            .catch((error) => {
                rollAppDataDispatch({ type: 'set-distribution-params', payload: undefined });
                handleClientError(error);
            });
    }, [ cancelAndSetDistributionParamsPromise, clientState, handleClientError, network, network.type ]);

    return rollAppData;
};
