import classNames from 'classnames';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../../../shared/components/badge/badge';
import ButtonsGroup from '../../../shared/components/buttons-group/buttons-group';
import ControlsComposer from '../../../shared/components/form-controls/controls-composer/controls-composer';
import Input from '../../../shared/components/form-controls/input/input';
import { Option } from '../../../shared/components/form-controls/options-modal/options-modal';
import Select from '../../../shared/components/form-controls/select/select';
import Icon from '../../../shared/components/icon/icon';
import InfoIndicator from '../../../shared/components/info-indicator/info-indicator';
import {
    getHistoryValuesInPeriod,
} from '../../../shared/components/statistics/statistics-change/statistics-change-service';
import useScrollPosition from '../../../shared/hooks/use-scroll-position';
import useWindowSize from '../../../shared/hooks/use-window-size';
import { getTimeLeftText, getTimeOffset } from '../../../shared/utils/date-utils';
import PoolApr from '../../amm/pools/pool-apr/pool-apr';
import { useLayout } from '../../app/layout/layout-context';
import { useAsset } from '../../asset/asset-context';
import ScrollingTradePairs from '../../asset/scrolling-trade-pairs/scrolling-trade-pairs';
import { useIRO } from '../../iro/iro-context';
import IroProgress from '../../iro/iro-progress/iro-progress';
import IroStatusIndicator from '../../iro/iro-status/iro-status-indicator';
import SimpleBondingCurve from '../../iro/simple-bonding-curve/simple-bonding-curve';
import { Network, ROLLAPP_STATUSES, RollappStatus } from '../../network/network-types';
import { formatPrice } from '../../../shared/utils/number-utils';
import Button from '../../../shared/components/button/button';
import Table, { TableColumn, TableRow } from '../../../shared/components/table/table';
import { getMaxDenomAmount } from '../../currency/currency-service';
import { ReactComponent as MagicWandIcon } from '../../../assets/icons/magic-wand.svg';
import { ReactComponent as DymensionLogo } from '../../../assets/logos/dymension-logo.svg';
import { ReactComponent as FairLaunchIcon } from '../../../assets/icons/fair-launch-tag.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { ReactComponent as MoreMenuIcon } from '../../../assets/icons/menu-more.svg';
import Spinner from '../../../shared/components/spinner/spinner';
import SimpleLineChart from '../../network/statistics/charts/simple-line-chart/simple-line-chart';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import RollappSummary from '../rollapp-summary/rollapp-summary';
import { ROLLAPP_TAGS, RollAppTag } from '../rollapp-types';
import RollappsHighlights from './rollapp-highlights/rollapp-highlights';
import { RollappsContextProvider, useRollapps } from './rollapps-context';
import StatisticsChange from '../../../shared/components/statistics/statistics-change/statistics-change';
import RollappsStatistics from './rollapps-statistics/rollapps-statistics';
import { useNetwork } from '../../network/network-context';
import './rollapps-page.scss';

const MAX_VISIBLE_ROLLAPP_TAGS = 5;
const SMALL_WINDOW_WIDTH = 540;

const RollappsPage: React.FC = () => {
    const navigate = useNavigate();
    const { loading, hubCurrency } = useNetwork();
    const {
        filteredRollapps,
        statusFilter,
        searchText,
        rollappsAnalyticsState,
        launchType,
        tagsFilter,
        orderBy,
        orderDirection,
        setOrder,
        setTagsFilter,
        switchTagFilter,
        setLaunchType,
        updateSearchText,
        updateStatusFilter,
        loadMore,
    } = useRollapps();
    const { setFooterClassName, setCtaClassName } = useLayout();
    const { mainAssetMap, loading: assetsLoading } = useAsset();
    const { getIroPlan, loading: iroLoading } = useIRO();
    const { isMobile, width } = useWindowSize();
    const { distribution, loading: distributionLoading } = useSponsorship();
    const { canLoadMore } = useScrollPosition();

    useEffect(() => {
        setFooterClassName('rollapps-page-footer');
        setCtaClassName('rollapps-page-cta');
        return () => {
            setFooterClassName();
            setCtaClassName();
        };
    }, [ setCtaClassName, setFooterClassName ]);

    useEffect(() => {
        if (canLoadMore) {
            loadMore();
        }
    }, [ loadMore, canLoadMore ]);

    const selectedHiddenTags = useMemo(
        () => ROLLAPP_TAGS.slice(isMobile ? 0 : MAX_VISIBLE_ROLLAPP_TAGS).filter((tag) => tagsFilter.includes(tag)),
        [ isMobile, tagsFilter ],
    );

    const renderRollappNameColumn = (rollapp: Network): ReactElement => {
        return <TableColumn className='name-column'><RollappSummary rollapp={rollapp} showFairLaunch showWebsite /></TableColumn>;
    };

    const renderSponsoredColumn = (rollapp: Network): ReactElement => {
        const sponsored = distribution?.find((record) => record.rollapp?.chainId === rollapp.chainId);

        return (
            <TableColumn align='right'>
                {!distribution && distributionLoading ? <Spinner size='small' /> : <>
                    {formatPrice(sponsored?.power || 0, '', { minimumFractionDigits: 0 })}&nbsp;
                    <small className='font-weight-600'>{hubCurrency?.displayDenom}</small>
                </>}
            </TableColumn>
        );
    };

    const renderMarketCapColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const totalSupply = getMaxDenomAmount(rollapp.totalSupply?.value.amount || 0, asset?.currency);
        const currentMarketCap = (asset?.price || 0) * totalSupply;
        const previousMarketCap = (asset?.previousDayPrice || 0) * totalSupply;
        const marketCapValue = currentMarketCap && <>
            {asset?.invalidMarketCap &&
                <Icon className='mc-warning-icon' tooltip='Misleading valuation due to illiquid token.'><WarningIcon /></Icon>}
            {formatPrice(currentMarketCap, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </>;
        return (
            <TableColumn align='right' key='market-cap' className={classNames('market-cap-column', { invalid: asset?.invalidMarketCap })}>
                {!mainAssetMap && assetsLoading ? <Spinner size='small' /> :
                    !currentMarketCap || asset?.futureIRO ? <span className='secondary-text'>N/A</span> :
                        statusFilter !== 'IRO' ? marketCapValue : (
                            <StatisticsChange
                                showChangeAffect
                                align='right'
                                period='day'
                                currentValue={currentMarketCap}
                                previousValue={previousMarketCap}
                            >
                                {marketCapValue}
                            </StatisticsChange>
                        )}
            </TableColumn>
        );
    };

    const renderIroProgressColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right' key='iro-progress'>
                {!asset?.iroProgress && iroLoading ? <Spinner size='small' /> : !asset?.iroProgress ?
                    <span className='secondary-text'>N/A</span> : <IroProgress className='iro-bonding-curv-progress' asset={asset} />}
            </TableColumn>
        );
    };

    const renderDymRaisedColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right' key='dym-raised'>
                {!asset?.iroDymRaised && iroLoading ? <Spinner size='small' /> : !asset?.iroDymRaised ?
                    <span className='secondary-text'>N/A</span> : formatPrice(asset.iroDymRaised, hubCurrency?.displayDenom)}
            </TableColumn>
        );
    };

    const renderIroTimeColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const iroPlan = asset && getIroPlan(asset.networkId);
        const today = new Date();
        const startTime = iroPlan?.startTime ? new Date(iroPlan.startTime) : today;
        const endTime = iroPlan?.preLaunchTime ? new Date(iroPlan.preLaunchTime) : today;

        return (
            <TableColumn
                align='right'
                key='iro-time'
                className={classNames('iro-time-column', { launchable: iroPlan && endTime <= today })}
            >
                {!iroPlan || !asset ? <span className='secondary-text'>N/A</span> : <>
                    <IroStatusIndicator asset={asset} className='iro-status-indicator' />
                    {endTime <= today ? 'Ready to Launch' :
                        getTimeLeftText(getTimeOffset(startTime > today ? startTime : endTime), undefined, true)}
                </>}
            </TableColumn>
        );
    };

    const renderLiquidityColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];

        return (
            <TableColumn align='right' key='liquidity'>
                {!mainAssetMap && assetsLoading ? <Spinner size='small' /> :
                    !asset || asset.futureIRO ? <span className='secondary-text'>N/A</span> :
                        formatPrice(asset?.liquidity || 0, undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
            </TableColumn>
        );
    };

    const renderPoolAprColumn = (rollapp: Network): ReactElement => {
        const pool = mainAssetMap?.[rollapp.chainId]?.pools?.[0];
        return (
            <TableColumn align='right' key='pool-apr'>
                {!pool || rollapp.tokenless ? <span className='secondary-text'>N/A</span> : <PoolApr pool={pool} />}
            </TableColumn>
        );
    };

    const renderStakingAprColumn = (rollapp: Network): ReactElement => {
        const pool = mainAssetMap?.[rollapp.chainId]?.pools?.[0];
        return (
            <TableColumn align='right' key='staking-apr'>
                {!pool || rollapp.tokenless ? <span className='secondary-text'>N/A</span> : <PoolApr pool={pool} />}
            </TableColumn>
        );
    };

    const renderTokenPriceColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right' key='price'>
                {rollapp.tokenless ? (
                    <Badge
                        className='dym-native-badge'
                        info='This RollApp is powered by DYM'
                        noWrap
                        rounded
                        size={isMobile ? 'small' : 'medium'}
                        label={<><Icon className='dym-native-badge-icon'><DymensionLogo /></Icon>DYM Native</>}
                    />
                ) : !mainAssetMap && assetsLoading ? <Spinner size='small' /> :
                    !asset || asset.futureIRO ? <span className='secondary-text'>N/A</span> : (
                        <StatisticsChange
                            showChangeAffect
                            align='right'
                            period='day'
                            currentValue={asset.price}
                            previousValue={asset.previousDayPrice}
                        >
                            {formatPrice(asset.price)}
                        </StatisticsChange>
                    )}
            </TableColumn>
        );
    };

    const renderPriceChangeColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        const data = rollappsAnalyticsState.analyticsMap?.[rollapp.chainId]?.totalSupply;
        const weekData = data && getHistoryValuesInPeriod(data, 'week');
        const isLoading = rollappsAnalyticsState.loadingMap?.[rollapp.chainId] !== false;

        return (
            <TableColumn align='right'>
                {!data && isLoading ? <Spinner className='price-chart-spinner' size='small' /> :
                    !weekData || rollapp.tokenless || !asset || asset.futureIRO ? <span className='secondary-text'>N/A</span> : (
                        <SimpleLineChart
                            historyList={weekData}
                            fetchComparableValues={({ marketCap, amount }) => marketCap && amount ? marketCap / amount : 0}
                        />
                    )}
            </TableColumn>
        );
    };

    const renderBondingCurveColumn = (rollapp: Network): ReactElement => {
        const asset = mainAssetMap?.[rollapp.chainId];
        return (
            <TableColumn align='right'>
                {iroLoading && !asset ? <Spinner className='price-chart-spinner' size='small' /> :
                    !asset ? <span className='secondary-text'>N/A</span> : <SimpleBondingCurve asset={asset} />}
            </TableColumn>
        );
    };

    const renderRollappsHeaderRow = (): ReactElement => {
        return (
            <TableRow header>
                <TableColumn
                    sortable
                    orderDirection={orderBy === 'name' ? orderDirection : ''}
                    onSort={(direction) => setOrder('name', direction)}
                >
                    Name
                </TableColumn>
                {statusFilter !== 'IRO' && (
                    <TableColumn
                        align='right'
                        info='Native token price'
                        key='price'
                        sortable
                        orderDirection={orderBy === 'price' ? orderDirection : ''}
                        onSort={(direction) => setOrder('price', direction)}
                    >
                        Token Price
                    </TableColumn>
                )}
                <TableColumn
                    align='right'
                    key='market-cap'
                    sortable
                    orderDirection={orderBy === 'market-cap' ? orderDirection : ''}
                    onSort={(direction) => setOrder('market-cap', direction)}
                >
                    FDV Market Cap
                </TableColumn>
                {statusFilter !== 'IRO' ? [
                    <TableColumn
                        align='right'
                        sortable
                        key='liquidity'
                        orderDirection={orderBy === 'liquidity' ? orderDirection : ''}
                        onSort={(direction) => setOrder('liquidity', direction)}
                    >
                        Liquidity
                    </TableColumn>,
                    <TableColumn
                        align='right'
                        key='pool-apr'
                        sortable
                        orderDirection={orderBy === 'pool-apr' ? orderDirection : ''}
                        onSort={(direction) => setOrder('pool-apr', direction)}
                        info={<>
                            <p>APR (Annual Percentage Rate) indicates the expected yearly yield for providing liquidity to the pool.</p>
                            <p>You can earn additional rewards by bonding your LP (Liquidity Provider) tokens.</p>
                        </>}
                    >
                        Pool APR
                    </TableColumn>,
                    // <TableColumn
                    //     align='right'
                    //     key='staking-apr'
                    //     sortable
                    //     orderDirection={orderBy === 'staking-apr' ? orderDirection : ''}
                    //     onSort={(direction) => setOrder('staking-apr', direction)}
                    // >
                    //     Staking APR
                    // </TableColumn>,
                ] : [
                    <TableColumn
                        sortable
                        key='iro-time'
                        align='right'
                        orderDirection={orderBy === 'iro-time' ? orderDirection : ''}
                        onSort={(direction) => setOrder('iro-time', direction)}
                        oppositeSort
                    >
                        Time Left
                    </TableColumn>,
                    <TableColumn
                        sortable
                        align='right'
                        key='dym-raised'
                        orderDirection={orderBy === 'dym-raised' ? orderDirection : ''}
                        onSort={(direction) => setOrder('dym-raised', direction)}
                    >
                        DYM Raised
                    </TableColumn>,
                    <TableColumn
                        sortable
                        key='iro-progress'
                        align='right'
                        orderDirection={orderBy === 'iro-progress' ? orderDirection : ''}
                        onSort={(direction) => setOrder('iro-progress', direction)}
                    >
                        Progress
                    </TableColumn>,
                ]}
                <TableColumn
                    align='right'
                    sortable
                    orderDirection={orderBy === 'total-endorsed' ? orderDirection : ''}
                    onSort={(direction) => setOrder('total-endorsed', direction)}
                    info='The total staked DYM endorsed for this RollApp. This value determines the proportion of DYM rewards allocated to it.'
                >
                    Total Endorsed
                </TableColumn>
                {statusFilter !== 'IRO' ?
                    <TableColumn align='right' nowrap>Price Change (7d)</TableColumn> :
                    <TableColumn align='right' nowrap>Bonding Curve</TableColumn>}
            </TableRow>
        );
    };

    const renderRollappRow = (rollapp: Network): ReactElement => {
        return (
            <TableRow key={rollapp.chainId} onSelect={() => navigate(`/rollapps/${rollapp.chainId}`)}>
                {renderRollappNameColumn(rollapp)}
                {statusFilter !== 'IRO' && renderTokenPriceColumn(rollapp)}
                {renderMarketCapColumn(rollapp)}
                {statusFilter !== 'IRO' ? [
                    renderLiquidityColumn(rollapp),
                    // renderStakingAprColumn(rollapp),
                    renderPoolAprColumn(rollapp),
                ] : [
                    renderIroTimeColumn(rollapp),
                    renderDymRaisedColumn(rollapp),
                    renderIroProgressColumn(rollapp),
                ]}
                {renderSponsoredColumn(rollapp)}
                {statusFilter !== 'IRO' ? renderPriceChangeColumn(rollapp) : renderBondingCurveColumn(rollapp)}
            </TableRow>
        );
    };

    const renderBottomBar = (): ReactElement | undefined => {
        if (loading) {
            return <div className='no-data'><Spinner /></div>;
        }
        if (!filteredRollapps.length) {
            return <div className='no-data'>No RollApps</div>;
        }
    };

    return (
        <div className='page rollapps-page'>
            <RollappsStatistics />

            <RollappsHighlights />

            <div className='rollapps-actionbar'>
                <div className='horizontally-centered rollapp-button-filters'>
                    <ButtonsGroup className='common-status-buttons'>
                        <Button
                            buttonType='secondary'
                            size='small'
                            onClick={() => updateStatusFilter()}
                            className={classNames('common-status', { hover: !statusFilter })}
                        >
                            RollApps
                        </Button>
                        <Button
                            buttonType='secondary'
                            size='small'
                            onClick={() => updateStatusFilter('IRO')}
                            className={classNames('common-status', { hover: statusFilter === 'IRO' })}
                        >
                            IROs
                            <InfoIndicator indicatorSize='small' tooltipClassName='iro-common-status-tooltip'>
                                IROs let projects launch tokens and allow early trading before the RollApp goes live.
                                It’s a fair and transparent way to seed liquidity and engage the community.
                                <ul className='list'>
                                    <li><b>Step 1:</b> Choose an IRO you want to participate in.</li>
                                    <li><b>Step 2:</b> Buy RollApp tokens using the bonding curve.</li>
                                    <li><b>Step 3:</b> Tokens can be sold at any time.</li>
                                    <li>
                                        <b>Step 4:</b> As more users buy on the bonding curve, liquidity accumulates for the RollApp, and upon launch, a trading pool will open at the last traded price.
                                    </li>
                                    <li>
                                        <b>Step 5:</b> Unsold tokens from the IRO will be used as incentives for liquidity providers after the RollApp launches.
                                    </li>
                                </ul>
                            </InfoIndicator>
                        </Button>
                    </ButtonsGroup>

                    <div className='switch-filters'>
                        <Button
                            buttonType='icon'
                            className={classNames('filter-switch fair-launch', { active: launchType === 'Fair Launch' })}
                            onClick={() => setLaunchType(launchType === 'Fair Launch' ? undefined : 'Fair Launch')}
                            size='small'
                        >
                            <FairLaunchIcon /> Fair Launch
                        </Button>

                        {statusFilter !== 'IRO' && (
                            <Button
                                buttonType='icon'
                                className={classNames('filter-switch', { active: launchType === 'DYM Native' })}
                                onClick={() => setLaunchType(launchType === 'DYM Native' ? undefined : 'DYM Native')}
                                size='small'
                            >
                                DYM Native
                            </Button>
                        )}

                        <div className='rollapp-tag-switches'>
                            {width > SMALL_WINDOW_WIDTH && ROLLAPP_TAGS.slice(0, 5).map((tag) => (
                                <Button
                                    buttonType='icon'
                                    key={tag}
                                    className={classNames('filter-switch', { active: tagsFilter.includes(tag) })}
                                    size='small'
                                    onClick={() => switchTagFilter(tag)}
                                >
                                    {tag}
                                </Button>
                            ))}
                            <Select
                                multiselect
                                value={tagsFilter}
                                className={classNames('tags-selector filter-switch', { active: selectedHiddenTags.length })}
                                buttonType='icon'
                                placeholder={<MoreMenuIcon />}
                                renderTriggerSelectedOption={() =>
                                    width <= SMALL_WINDOW_WIDTH ? <MoreMenuIcon /> : !selectedHiddenTags.length ?
                                        <MoreMenuIcon /> : <small className='more-label'>&nbsp;(+{selectedHiddenTags.length} more)</small>}
                                onSelect={(value) => setTagsFilter(value as RollAppTag[])}
                            >
                                {ROLLAPP_TAGS.map((tag) => <Option value={tag} key={tag}>{tag}</Option>)}
                            </Select>
                        </div>
                    </div>
                </div>

                <span className='space' />

                <div className='rollapps-actions'>
                    <ControlsComposer className='rollapps-filters'>
                        <Input
                            controlSize='medium'
                            value={searchText}
                            type='search'
                            placeholder='Search rollapps...'
                            onValueChange={updateSearchText}
                        />
                        <Select
                            value={statusFilter || ''}
                            className='rollapp-status-select'
                            optionsOverlayAlign='right'
                            onSelect={(type) => updateStatusFilter(type as RollappStatus)}
                            controlSize='medium'
                        >
                            {ROLLAPP_STATUSES.map((status) => (
                                <Option key={status} value={status}>{status}</Option>
                            ))}
                            <Option key='all' value=''>All</Option>
                        </Select>
                    </ControlsComposer>

                    <Button
                        buttonType='primary'
                        className='deploy-rollapp-button'
                        onClick={() => navigate('/rollapps/manage/create')}
                    >
                        <MagicWandIcon /><span className='deploy-rollapp-button-text'>Create a RollApp</span>
                    </Button>
                </div>
            </div>

            <div className={classNames('rollapps-table-container', launchType?.toLowerCase())}>
                <Table indexColumn firstColumnSticky bottomBar={renderBottomBar()}>
                    {renderRollappsHeaderRow()}
                    {filteredRollapps.map(renderRollappRow)}
                </Table>
            </div>

            <ScrollingTradePairs className='scrolling-trade-pairs' />
        </div>
    );
};

const RollappsPageWithContext = () =>
    <RollappsContextProvider><RollappsPage /></RollappsContextProvider>;

export default RollappsPageWithContext;
