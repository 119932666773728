import React, { useCallback, useMemo } from 'react';
import Property from '../../../../shared/components/property/property';
import StatisticsChange from '../../../../shared/components/statistics/statistics-change/statistics-change';
import { convertDecimalToInt, roundNumber } from '../../../../shared/utils/number-utils';
import { useNetworkDashboard } from '../../../network-dashboard/network-dashboard-context';

const StakingAprProperty: React.FC = () => {
    const { network, rollappData } = useNetworkDashboard();

    const fetchComparableValues = useCallback((value?: { amount: number, bondedAmount: number, inflation: number }): number => {
        if (!value) {
            return 0;
        }
        if (!value.bondedAmount) {
            return Infinity;
        }
        const inflationValue = convertDecimalToInt(value.inflation || 0) * 100;
        let apr = inflationValue * value.amount / value.bondedAmount;
        if (rollappData.distributionParams) {
            const { communityTax, baseProposerReward, bonusProposerReward } = rollappData.distributionParams;
            apr *= (1 - communityTax - baseProposerReward - bonusProposerReward);
        }
        return apr;
    }, [ rollappData.distributionParams ]);

    const formatValue = useCallback((value: number) => value === Infinity ? `${Infinity.toString()}%` : `${roundNumber(value, 2)}%`, []);

    const currentValue = useMemo(
        () => fetchComparableValues(network.totalSupply?.value),
        [ fetchComparableValues, network.totalSupply?.value ],
    );

    const previousValue = useMemo(
        () => fetchComparableValues(network.totalSupply?.previousWeekValue),
        [ fetchComparableValues, network.totalSupply?.previousWeekValue ],
    );

    return (
        <Property label='Staking APR' na={!currentValue || !network.totalSupply || currentValue === Infinity}>
            <StatisticsChange period='week' currentValue={currentValue} previousValue={previousValue}>
                {formatValue(currentValue)}
            </StatisticsChange>
        </Property>
    );
};

export default StakingAprProperty;
