import { useCallback, useState } from 'react';

type PersistMode = 'storage' | 'session' | 'none';

const SESSION_MAP: { [key: string]: any } = {};

export const usePersistedState = <T>(
    keyName: string,
    defaultValue: T,
    optionalValues?: readonly T[],
    persistMode: PersistMode = 'storage',
): [ T, (value: T | ((previousValue: T) => T)) => void, (value: T) => void ] => {
    const [ value, setValue ] = useState<T>(getInitialValue(keyName, defaultValue, persistMode, optionalValues));

    const savePersistedValue = useCallback((value: T): void => {
        if (persistMode === 'session') {
            SESSION_MAP[keyName] = value;
        } else if (persistMode === 'storage') {
            if (value !== undefined && value !== null) {
                localStorage.setItem(keyName, JSON.stringify(value, (key, value) =>
                    typeof value === 'bigint' ? value.toString() + 'n' : value,
                ));
            } else {
                localStorage.removeItem(keyName);
            }
        }
    }, [ keyName, persistMode ]);

    const setPersistedValue = useCallback((value: T | ((previousValue: T) => T)): void => {
        if (value === undefined || value === null || !optionalValues || typeof value === 'function' || optionalValues.includes(value)) {
            setValue((previousValue) => {
                if (typeof value === 'function') {
                    value = (value as (previousValue: T) => T)(previousValue);
                }
                savePersistedValue(value);
                return value;
            });
        }
    }, [ optionalValues, savePersistedValue ]);

    return [ value, setPersistedValue, savePersistedValue ];
};

const getInitialValue = <T>(keyName: string, defaultValue: T, persist?: PersistMode, optionalValues?: readonly T[]): T => {
    if (persist === 'session') {
        return SESSION_MAP[keyName] as T || defaultValue;
    }
    if (persist === 'storage') {
        try {
            const storedValue = persist ? localStorage.getItem(keyName) : null;
            if (storedValue !== null) {
                const value = JSON.parse(storedValue, (key, value) =>
                    typeof value === 'string' && /^\d+n$/.test(value) ? BigInt(value.replace('n', '')) : value,
                ) as T;
                if (!optionalValues || optionalValues.includes(value)) {
                    return value;
                }
            }
        } catch {}
    }
    return defaultValue;
};
