import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDirection } from '../../../shared/types';
import { useAmm } from '../../amm/amm-context';
import { analyticsMapReducer, AnalyticsMapState } from '../../analytics/analytics-map-state';
import { useAsset } from '../../asset/asset-context';
import { useDymns } from '../../dymns/dymns-context';
import { useIRO } from '../../iro/iro-context';
import { Network, ROLLAPP_STATUSES, RollappStatus } from '../../network/network-types';
import { useNetwork } from '../../network/network-context';
import { loadNetworkAnalyticsMap } from '../../network/statistics/analytics/network-analytics-service';
import { NetworksAnalytics } from '../../network/statistics/analytics/network-analytics-types';
import { useSponsorship } from '../../sponsorship/sponsorship-context';
import { LaunchType, RollappListColumnType, RollAppTag } from '../rollapp-types';

interface RollappContextValue {
    statusFilter?: RollappStatus;
    searchText?: string;
    filteredRollapps: Network[];
    rollappsAnalyticsState: AnalyticsMapState<{ [networkId: string]: NetworksAnalytics }>;
    loadMore: () => void;
    updateStatusFilter: (value?: RollappStatus) => void;
    updateSearchText: (value?: string) => void;
    launchType?: LaunchType;
    tagsFilter: RollAppTag[];
    orderBy?: RollappListColumnType;
    orderDirection?: OrderDirection;
    setOrder: (type: RollappListColumnType, direction: OrderDirection) => void;
    switchTagFilter: (tag: RollAppTag) => void;
    setTagsFilter: (tags: RollAppTag[]) => void;
    setLaunchType: (value?: LaunchType) => void;
}

const PAGE_SIZE = 10;

export const RollappsContext = createContext<RollappContextValue>({} as RollappContextValue);

export const useRollapps = (): RollappContextValue => useContext(RollappsContext);

export const RollappsContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const navigate = useNavigate();
    const { rollapps } = useNetwork();
    const { dymnsState } = useDymns();
    const { mainAssetMap } = useAsset();
    const { incentiveAprs } = useAmm();
    const { sponsorshipPoolAprs } = useSponsorship();
    const { getIroPlan } = useIRO();
    const [ page, setPage ] = useState(0);
    const [ rollappsAnalyticsState, rollappsAnalyticsStateDispatch ] = useReducer(analyticsMapReducer, {});
    const [ searchText, setSearchText ] = useState<string>();
    const [ tagsFilter, setTagsFilter ] = useState<RollAppTag[]>([]);
    const [ orderBy, setOrderBy ] = useState<RollappListColumnType>();
    const [ orderDirection, setOrderDirection ] = useState<OrderDirection>();
    const [ launchType, setLaunchType ] = useState<LaunchType>();
    const { status } = useParams();

    const statusFilter = useMemo(
        () => status && ROLLAPP_STATUSES.includes(status as RollappStatus) ? status as RollappStatus : undefined,
        [ status ],
    );

    const updateSearchText = useCallback((value?: string) => {
        setPage(0);
        setSearchText(value);
    }, []);

    const updateStatusFilter = useCallback((value: RollappStatus | undefined) => {
        setPage(0);
        navigate(`/rollapps${value ? `/filter/${value}` : ''}`, { replace: true });
    }, [ navigate ]);

    useEffect(() => {
        if (statusFilter || launchType) {
            setPage(0);
        }
    }, [ statusFilter, launchType ]);

    const switchTagFilter = useCallback((tag: RollAppTag) => {
        setTagsFilter((tags) => {
            const tagIndex = tags.indexOf(tag);
            if (tagIndex >= 0) {
                tags.splice(tagIndex, 1);
                return [ ...tags ];
            }
            return [ ...tags, tag ];
        });
    }, []);

    const sortRollapps = useCallback((rollapps: Network[]): Network[] => {
        const sortedRollapps = [ ...rollapps ];
        if (statusFilter === 'IRO') {
            sortedRollapps.sort((rollapp1, rollapp2) => {
                const asset1 = mainAssetMap?.[rollapp1.chainId];
                const asset2 = mainAssetMap?.[rollapp2.chainId];
                return (asset2?.iroDymRaised || 0) - (asset1?.iroDymRaised || 0);
            });
        }
        if (!orderBy || !orderDirection) {
            return sortedRollapps;
        }
        const factor = orderDirection === 'asc' ? -1 : 1;
        return sortedRollapps.sort((rollapp1, rollapp2) => {
            const asset1 = mainAssetMap?.[rollapp1.chainId];
            const asset2 = mainAssetMap?.[rollapp2.chainId];

            if (orderBy === 'name') {
                return rollapp1.chainName.localeCompare(rollapp2.chainName) * factor;
            }
            if (orderBy === 'total-endorsed') {
                const rollapp1Sponsored = rollapp1.totalSponsored?.value.power || 0;
                const rollapp2Sponsored = rollapp2.totalSponsored?.value.power || 0;
                const diff = rollapp1Sponsored - rollapp2Sponsored;
                return !rollapp1Sponsored || !rollapp2Sponsored ? -diff : diff * factor;
            }
            if (statusFilter !== 'IRO') {
                if (orderBy === 'tvl') {
                    const rollapp1Tvl = rollapp1.totalSupply?.value.tvl || 0;
                    const rollapp2Tvl = rollapp2.totalSupply?.value.tvl || 0;
                    const diff = rollapp1Tvl - rollapp2Tvl;
                    return !rollapp1Tvl || !rollapp2Tvl ? -diff : diff * factor;
                }
                if (orderBy === 'ibc-transfers') {
                    const rollapp1IbcTransfers = (rollapp1.ibcTransfers?.value.totalIn || 0) + (rollapp1.ibcTransfers?.value.totalOut || 0);
                    const rollapp2IbcTransfers = (rollapp2.ibcTransfers?.value.totalIn || 0) + (rollapp2.ibcTransfers?.value.totalOut || 0);
                    const diff = rollapp1IbcTransfers - rollapp2IbcTransfers;
                    return !rollapp1IbcTransfers || !rollapp2IbcTransfers ? -diff : diff * factor;
                }
                if (orderBy === 'pool-apr') {
                    const pool1 = asset1?.pools[0];
                    const pool2 = asset2?.pools[0];
                    const apr1 = (pool1?.apr || 0) + ((pool1 && (incentiveAprs[pool1.id] + sponsorshipPoolAprs[pool1.id])) || 0);
                    const apr2 = (pool2?.apr || 0) + ((pool2 && (incentiveAprs[pool2.id] + sponsorshipPoolAprs[pool2.id])) || 0);
                    const diff = apr1 - apr2;
                    return !apr1 || !apr2 ? -diff : diff * factor;
                }
                if (orderBy === 'volume') {
                    const diff = (asset1?.volume || 0) - (asset2?.volume || 0);
                    return !asset1?.volume || !asset2?.volume ? -diff : diff * factor;
                }
                if (orderBy === 'liquidity') {
                    const diff = (asset1?.liquidity || 0) - (asset2?.liquidity || 0);
                    return !asset1?.liquidity || !asset2?.liquidity ? -diff : diff * factor;
                }
            } else {
                if (orderBy === 'dym-raised') {
                    const diff = (asset1?.iroDymRaised || 0) - (asset2?.iroDymRaised || 0);
                    return !asset1?.iroDymRaised || !asset2?.iroDymRaised ? -diff : diff * factor;
                }
                if (orderBy === 'iro-progress') {
                    const diff = (asset1?.iroProgress || 0) - (asset2?.iroProgress || 0);
                    return !asset1?.iroProgress || !asset2?.iroProgress ? -diff : diff * factor;
                }
                if (orderBy === 'iro-time') {
                    const iroPlan1 = asset1 && getIroPlan(asset1.networkId);
                    const iroPlan2 = asset2 && getIroPlan(asset2.networkId);
                    const today = new Date();
                    const startTime1 = iroPlan1?.startTime ? new Date(iroPlan1.startTime) : today;
                    const endTime1 = iroPlan1?.preLaunchTime ? new Date(iroPlan1.preLaunchTime) : today;
                    const startTime2 = iroPlan2?.startTime ? new Date(iroPlan2.startTime) : today;
                    const endTime2 = iroPlan2?.preLaunchTime ? new Date(iroPlan2.preLaunchTime) : today;
                    const readyToLaunch1 = endTime1 <= new Date();
                    const readyToLaunch2 = endTime2 <= new Date();
                    const diff = readyToLaunch1 !== readyToLaunch2 ? (readyToLaunch1 ? 1 : 0) - (readyToLaunch2 ? 1 : 0) :
                        Math.abs((asset1?.futureIRO ? startTime1 : endTime1).getTime() - today.getTime()) -
                        Math.abs((asset2?.futureIRO ? startTime2 : endTime2).getTime() - today.getTime());
                    return !iroPlan1 || !iroPlan2 ? -diff : diff * factor;
                }
            }
            if (Boolean(asset1?.invalidMarketCap) !== Boolean(asset2?.invalidMarketCap)) {
                return (asset1?.invalidMarketCap ? 1 : 0) - (asset2?.invalidMarketCap ? 1 : 0);
            }
            if (statusFilter !== 'IRO' && orderBy === 'price') {
                const diff = ((asset1?.price || 0) - (asset2?.price || 0));
                return !asset1?.price || !asset2?.price ? -diff : diff * factor;
            }
            if (orderBy === 'market-cap') {
                const currentMarketCap1 = (asset1?.price || 0) * (rollapp1.totalSupply?.value.amount || 0);
                const currentMarketCap2 = (asset2?.price || 0) * (rollapp2.totalSupply?.value.amount || 0);
                const diff = currentMarketCap1 - currentMarketCap2;
                return !currentMarketCap1 || !currentMarketCap2 ? -diff : diff * factor;
            }
            return 0;
        });
    }, [ getIroPlan, incentiveAprs, mainAssetMap, orderBy, orderDirection, statusFilter ]);

    const filteredRollapps = useMemo(() => {
        const lowerCaseSearchText = searchText?.toLowerCase();
        const filteredRollapps = rollapps
            .filter((rollapp) => statusFilter ? rollapp.status === statusFilter : rollapp.status !== 'IRO')
            .filter((rollapp) => launchType !== 'Fair Launch' || rollapp.fairLaunch)
            .filter((rollapp) => launchType !== 'DYM Native' || rollapp.tokenless)
            .filter((rollapp) => !tagsFilter.length || rollapp.tags?.some((tag) => tagsFilter.includes(tag)))
            .filter((rollapp) => !lowerCaseSearchText ||
                rollapp.chainId.includes(lowerCaseSearchText) ||
                rollapp.chainName.toLowerCase().includes(lowerCaseSearchText) ||
                rollapp.description?.toLowerCase().includes(lowerCaseSearchText) ||
                rollapp.shortDescription?.toLowerCase().includes(lowerCaseSearchText) ||
                rollapp.currencies.some((currency) =>
                    currency.displayDenom.toLowerCase().includes(lowerCaseSearchText) ||
                    currency.baseDenom.toLowerCase().includes(lowerCaseSearchText)) ||
                dymnsState.aliasesMap[rollapp.chainId]?.aliases?.[0]?.toLowerCase().includes(lowerCaseSearchText),
            );
        return sortRollapps(filteredRollapps).slice(0, (page + 1) * PAGE_SIZE);
    }, [ dymnsState.aliasesMap, launchType, page, rollapps, searchText, sortRollapps, statusFilter, tagsFilter ]);

    const loadMore = useCallback(() => {
        if ((page + 1) * PAGE_SIZE === filteredRollapps.length) {
            setPage(page + 1);
        }
    }, [ page, filteredRollapps.length ]);

    useEffect(() => {
        const rollappIds = filteredRollapps
            .map((rollapp) => rollapp.chainId)
            .filter((rollappId) => !rollappsAnalyticsState?.analyticsMap?.[rollappId] && !rollappsAnalyticsState?.loadingMap?.[rollappId]);

        if (!rollappIds.length) {
            return;
        }
        rollappsAnalyticsStateDispatch({ type: 'set-loading', payload: { ids: rollappIds } });
        loadNetworkAnalyticsMap<keyof NetworksAnalytics>(rollappIds, { totalSupply: [ 'month' ] })
            .then((analytics) => rollappsAnalyticsStateDispatch({ type: 'set-analytics', payload: analytics }))
            .catch((error) => rollappsAnalyticsStateDispatch({ type: 'set-error', payload: error }));
    }, [ filteredRollapps, rollappsAnalyticsState?.analyticsMap, rollappsAnalyticsState?.loadingMap ]);

    useEffect(() => {
        if (statusFilter === 'IRO' && launchType === 'DYM Native') {
            setLaunchType(undefined);
        }
    }, [ launchType, statusFilter ]);

    const setOrder = useCallback((type: RollappListColumnType, direction: OrderDirection): void => {
        setOrderBy(type);
        setOrderDirection(direction);
        setPage(0);
    }, []);

    return (
        <RollappsContext.Provider
            value={{
                statusFilter,
                searchText,
                filteredRollapps,
                loadMore,
                tagsFilter,
                rollappsAnalyticsState,
                updateSearchText,
                updateStatusFilter,
                launchType,
                switchTagFilter,
                orderBy,
                orderDirection,
                setOrder,
                setTagsFilter,
                setLaunchType,
            }}
        >
            {children}
        </RollappsContext.Provider>
    );
};
