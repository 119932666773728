import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect } from 'react';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { TxValue, useTx } from '../../../tx/use-tx';
import { useEibcClient } from '../eibc-client-context';
import { createGrantFulfillOrderAuthorizationMessage } from '../eibc-client-serice';
import { RollappLiquidity } from '../eibc-client-types';

interface UseRemoveRollappLiquidityValue extends Pick<TxValue, 'txState' | 'broadcast'> {}

export const useRemoveRollappLiquidity = (liquidity: RollappLiquidity): UseRemoveRollappLiquidityValue => {
    const { handleClientError } = useClient();
    const { liquidityList } = useEibcClient();
    const networkState = useHubNetworkState();

    const revokeAuthorizationMessageCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !liquidity.operatorGroupAddress) {
            return [];
        }
        const message = createGrantFulfillOrderAuthorizationMessage(networkState.address, liquidity, liquidityList, true);
        return message ? [ message ] : [];
    }, [ networkState.address, liquidity, liquidityList ]);

    const { txState, broadcast, calculateFee, clearFee } = useTx({
        networkState: networkState,
        txMessagesCreator: revokeAuthorizationMessageCreator,
        signMethod: 'direct',
    });

    useEffect(() => {
        if (networkState.address && liquidity.operatorGroupAddress) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, liquidity.operatorGroupAddress, networkState.address ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, txState.error ]);

    return { broadcast, txState };
};
