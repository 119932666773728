import { EncodeObject } from 'cosmjs/packages/proto-signing';
import { useCallback, useEffect, useState } from 'react';
import { useHubNetworkState } from '../../../account/hub-network-state-context';
import { useClient } from '../../../client/client-context';
import { ClientError } from '../../../client/client-error';
import { TxValue, useTx } from '../../../tx/use-tx';
import { useEibcClient } from '../eibc-client-context';
import { createGrantFulfillOrderAuthorizationMessage } from '../eibc-client-serice';
import { RollappLiquidity } from '../eibc-client-types';

interface UseRollappLiquidityValue extends Pick<TxValue, 'txState'> {
    liquidity: Partial<RollappLiquidity>;
    updateLiquidity: (data: Partial<RollappLiquidity>) => void;
    save: () => void;
    showErrors: boolean;
}

const DEFAULT_ROLLAPP_LIQUIDITY: Partial<RollappLiquidity> = {
    minLpFee: 0.0015,
    operatorFeeShare: 0.2,
};

export const useRollappLiquidity = (editedLiquidity?: RollappLiquidity): UseRollappLiquidityValue => {
    const { handleClientError } = useClient();
    const networkState = useHubNetworkState();
    const { liquidityList } = useEibcClient();
    const [ liquidity, setLiquidity ] = useState<Partial<RollappLiquidity>>(editedLiquidity || DEFAULT_ROLLAPP_LIQUIDITY);
    const [ showErrors, setShowErrors ] = useState(false);

    const updateLiquidity = useCallback((data: Partial<RollappLiquidity>): void => {
        setLiquidity((liquidity) => ({ ...liquidity, ...data }));
    }, []);

    const grantAuthorizationMessageCreator = useCallback((): EncodeObject[] => {
        if (!networkState.address || !liquidity.operatorGroupAddress || !liquidity.rollapp?.chainId || !liquidity.tokens?.length) {
            return [];
        }
        const message = createGrantFulfillOrderAuthorizationMessage(networkState.address, liquidity as RollappLiquidity, liquidityList);
        return message ? [ message ] : [];
    }, [ networkState.address, liquidity, liquidityList ]);

    const { txState, broadcast, calculateFee, clearFee } = useTx({
        networkState: networkState,
        txMessagesCreator: grantAuthorizationMessageCreator,
        signMethod: 'direct',
    });

    useEffect(() => {
        if (networkState.address && liquidity.operatorGroupAddress && liquidity.rollapp?.chainId) {
            calculateFee();
        } else {
            clearFee();
        }
    }, [ calculateFee, clearFee, liquidity.operatorGroupAddress, liquidity.rollapp?.chainId, networkState.address ]);

    useEffect(() => {
        if (!txState.error) {
            return;
        }
        if (txState.error instanceof ClientError) {
            handleClientError(txState.error);
        } else {
            console.error(txState.error);
        }
        calculateFee(false);
    }, [ calculateFee, handleClientError, txState.error ]);

    const save = useCallback(() => {
        if (!liquidity.operatorGroupAddress ||
            !liquidity.rollapp?.chainId ||
            !liquidity.operatorFeeShare ||
            !liquidity.minLpFee ||
            !liquidity.tokens?.length ||
            liquidity.tokens.some((token) => !token.amount)) {
            setShowErrors(true);
            return;
        }
        setShowErrors(false);
        broadcast().then();
    }, [
        broadcast,
        liquidity.minLpFee,
        liquidity.operatorFeeShare,
        liquidity.operatorGroupAddress,
        liquidity.rollapp?.chainId,
        liquidity.tokens,
    ]);

    return { showErrors, txState, liquidity, updateLiquidity, save };
};
