export interface DistributionParams {
    communityTax: number;
    baseProposerReward: number;
    bonusProposerReward: number;
}

export const ROLLAPP_TAGS = [ 'Meme', 'AI', 'Gaming', 'NFT', 'DeFi', 'Betting', 'Community', 'Social', 'DePIN', 'Launchpad' ] as const;
export type RollAppTag = typeof ROLLAPP_TAGS[number];

export type RollappListColumnType =
    'name'
    | 'apr'
    | 'price'
    | 'market-cap'
    | 'volume'
    | 'liquidity'
    | 'total-endorsed'
    | 'tvl'
    | 'ibc-transfers'
    | 'dym-raised'
    | 'iro-progress'
    | 'iro-time'

export type LaunchType = 'Fair Launch' | 'DYM Native';
